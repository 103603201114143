<template>
  <div class="app-navbar">
    <v-app-bar app :elevation="!!scrollTopValue ? 3 : 1" height="62">
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
      ></v-progress-linear>
      <v-layout row wrap align-center>
        <v-flex lg4>
          <v-icon @click="app_drawer= !app_drawer" class="px-2 px-lg-3 pb-1" v-if="!app_drawer && xsOnly" color="black">
            mdi-menu
          </v-icon>
          <span :class="[!app_drawer ? '' : 'px-4', 'title font-weight-medium']"
          @click="$route.path !== `/dashboard` && $router.push(`/dashboard`)"
          style="cursor: pointer; color: black"></span>
        </v-flex>
        <v-flex lg8 class="text-right">
          <webclockin v-if="!userDetails.isdeveloper"></webclockin>
          <v-menu class="toolbar-menu-item" offset-y origin="center center" :nudge-bottom="10" transition="scale-transition">
            <template #activator="{ on }">
              <v-btn v-on="on" icon text @click="notifications.map(x => x.isActive = false)">
                <v-badge color="green" overlap v-on="on">
                  <span slot="badge" v-if="notifications.filter(x => x.isActive).length > 0">
                    {{ notifications.filter(x => x.isActive).length }}
                  </span>
                  <v-icon color="black" medium> mdi-bell-ring-outline </v-icon>
                </v-badge>
              </v-btn>
            </template>
            <v-card class="elevation-0">
              <v-toolbar flat dense>
                <span class="title font-weight-bold">You have {{ notifications.length }} new notification(s)</span>
              </v-toolbar>
              <v-card-text class="pa-0">
                <v-list two-line class="pa-0">
                  <template v-for="(item, index) in notifications">
                    <v-divider :key="index" />
                    <v-list-item :key="`${index}_1`">
                      <v-list-item-avatar :color="item.color"> 
                        <v-icon dark>{{item.icon}}</v-icon> 
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-truncate"> {{item.title}} </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="caption"> {{item.actionAt}} </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
                <v-divider></v-divider>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-menu transition="slide-x-transition" bottom nudge-bottom="25" nudge-right="40">
            <template #activator="{ on, attrs }">
              <v-avatar class="mx-4" :size="$vuetify.breakpoint.xsOnly ? 33 : 38" v-bind="attrs" v-on="on">
                <img :src="userDetails.profile" alt="John" :key="profileKey">
              </v-avatar>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in avatarMenuOptions" :key="index" :to="item.to" @click="item.atClick && (item.atClick)()">
                <v-list-item-icon>
                  <v-icon size="20"> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="ml-n2"> {{ item.title }} </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>
    </v-app-bar>
    <v-navigation-drawer
      mini-variant-width="65" app v-model="app_drawer" dark width="230" mini-variant :color="($vuetify.theme.dark) ? 'grey darken-4' : '#002749'"
      ref="menubar" disable-resize-watcher disable-route-watcher permanent
    >
      <template #prepend>
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <v-list-item class="py-2" v-bind="attrs" v-on="on" to="/dashboard" draggable="false">
              <v-icon size="21.5"> mdi-view-dashboard </v-icon>
            </v-list-item>
            <v-divider dark></v-divider>
          </template>
          <span class="d-flex justify-center ml-1"> Dashboard </span>
        </v-tooltip>
      </template>
      <template v-for="(item, index_i) in menuItems" class="py-1">
        <template v-if="item.childrens && item.childrens.length">
          <v-menu
            :nudge-width="190" offset-x nudge-right="3" nudge-top="1" :key="index_i"
          >
            <template #activator="{ on, attrs }">
              <v-list-item
                class="py-1 navbar-menu-item" v-if="item.module ? checkforPermission(item.module) : true" :key="index_i"
                :to="item.to" v-bind="attrs" v-on="on"
                draggable @dragstart="dragStartHandler" @dragover="dragHandler" @dragend="dropHandler" :data-menu-index="index_i"
              >
                <v-icon size="21.5" :data-menu-index="index_i"> {{ item.icon }} </v-icon>
              </v-list-item>
            </template>
            <v-card class="d-flex justify-center" color="#002749" width="100%">
              <v-list color="#002749">
                <div class="d-flex justify-center font-weight-black py-2 white--text"> {{ item.text }} </div>
                <v-divider class="grey"></v-divider>
                <v-list-item v-for="(item, i) in item.childrens" :key="i" :to="item.to">
                  <v-list-item-action><v-icon class="mr-3" color="white">{{item.icon}}</v-icon></v-list-item-action>
                  <v-list-item-title class="white--text"> {{ item.text }} </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
        <template v-else-if="(typeof item.show === 'boolean') ? item.show : true">
          <v-tooltip right :key="index_i">
            <template #activator="{ on, attrs }">
              <v-list-item
                class="py-1 navbar-menu-item" v-if="item.module ? checkforPermission(item.module) : true" :key="index_i"
                :to="item.to" v-bind="attrs" v-on="on"
                draggable @dragstart="dragStartHandler" @dragover="dragHandler" @dragend="dropHandler" :data-menu-index="index_i"
              >
                <v-icon size="21.5" :data-menu-index="index_i"> {{ item.icon }} </v-icon>
              </v-list-item>
            </template>
            <div style="border: 6px solid; border-color: transparent #888 transparent transparent; position: absolute; top: calc(30%); left: -12px;"></div>
            <span class="d-flex justify-center ml-1"> {{ item.text }} </span>
          </v-tooltip>
        </template>
      </template>
      <template #append>
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <v-divider dark></v-divider>
            <v-list-item class="py-2" v-bind="attrs" v-on="on" to="/settings" draggable="false">
              <v-icon size="21.5"> mdi-cog </v-icon>
            </v-list-item>
          </template>
          <span class="d-flex justify-center ml-1"> Settings </span>
        </v-tooltip>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loading: false,
      app_drawer: true,
      formModuleList: [],
      scrollTopValue: 0,
      disableClockIn: false,
      webClockinObj: {},
      profileKey: 0,
      notifications: [{
        title: 'New mail from Adam Joe',
        color: 'light-blue',
        icon: 'mdi-email',
        actionAt: '12 min ago',
        isActive: true,
        onClick: () => this.$router.push({ name: 'Mailbox' })
      }, {
        title: 'Scheculed meeting',
        color: 'red',
        icon: 'mdi-calendar',
        actionAt: '46 min ago',
        isActive: true,
        onClick: () => this.$router.push({ name: 'Calendar' })
      }, {
        title: 'New mail from Github',
        color: 'light-blue',
        icon: 'mdi-email',
        isActive: true,
        timeLabel: '2 hour ago',
        onClick: () => this.$router.push({ name: 'Mailbox' })
      }],
      menuItems: [],
      onDragItem: null,
      dropOnItem: null
    }
  },
  components: {
    webclockin: () => import('@/components/WebClockin.vue')
  },
  beforeMount () {
    this.getModuleFormsList()
    this.menuItems = [...this.menusList]
    window.onscroll = () => { this.scrollTopValue = document.documentElement.scrollTop }
    this.app_drawer = !this.$vuetify.breakpoint.xsOnly
  },
  mounted () {
    this.$root.$on('updateProfilePicture', () => ++this.profileKey)
  },
  computed: {
    menusList () {
      let menusList = []
      if (this.userDetails.isdeveloper) {
        menusList = [{
          text: 'Tenants',
          icon: 'mdi-arch',
          to: '/tenants'
        }, {
          text: 'Techilex | Contact-us',
          icon: 'mdi-tablet-cellphone',
          to: '/techilex-contactus'
        }, {
        text: 'Forms',
        icon: 'mdi-widgets-outline',
        module: 'forms',
        to: `/forms`,
      }]
      } else {
        menusList = [{
          text: 'Calendar',
          icon: 'mdi-calendar-today',
          to: `/calendar`,
          module: 'calendar'
        }, {
          text: 'Attendance',
          icon: 'mdi-fingerprint',
          module: 'attendance',
          childrens: [{
            text: 'Student Attendance',
            icon: 'mdi-account',
            to: `/attendance/student`
          }, {
            text: 'Staff Attendance',
            icon: 'mdi-server',
            to: `/attendance/staff`
          }, {
            text: 'Settings',
            icon: 'mdi-server',
            to: `/attendance/manage`
          }]
        }, {
          text: 'Leave Management',
          icon: 'mdi-alpha-l-box-outline',
          to: `/leave`,
          module: 'leave_management'
        }, {
          text: 'Academic',
          icon: 'mdi-gitlab',
          module: 'academic',
          to: `/academic/classrooms`
        }, {
          text: 'Library',
          icon: 'mdi-library',
          module: 'library',
          to: `/library/issue-book`
        // }, {
        //   text: 'Reports',
        //   icon: 'mdi-ballot-outline',
        //   to: `/reports`,
        //   module: 'reports',
        }, {
          text: 'Communication',
          icon: 'mdi-inbox-multiple',
          module: 'chats',
          childrens: [{
            text: 'Mail',
            icon: 'mdi-email-newsletter',
            to: `/communication/mail`
          }, {
            text: 'Sms',
            icon: 'mdi-message-processing',
            to: `/communication/sms`
          }, {
            text: 'Chat',
            icon: 'mdi-chat-processing',
            to: `/communication/chat`
          }]
        }, {
          text: 'Forms',
          icon: 'mdi-widgets-outline',
          module: 'forms',
          to: `/forms`,
        }, {
          text: 'Exam',
          icon: 'mdi-clipboard',
          module: 'exam',
          to: `/exam`
        }, {
          text: 'Transportaion',
          icon: 'mdi-bus',
          module: 'transportion',
          to: `/transportion`
        }, {
          text: 'Gatepass',
          icon: 'mdi-ticket-confirmation-outline',
          module: 'gatepass',
          to: `/gate-pass`
        }, {
        // }, {
        //   text: 'Accounting',
        //   icon: 'mdi-currency-btc',
        //   to: `/payments`,
        //   module: 'attendance'
          text: 'Hostel',
          icon: 'mdi-city',
          module: 'hostel',
          to: `/hostel`
        }, {
        //   text: 'Enquiry',
        //   icon: 'mdi-head-question',
        //   module: 'enquiry',
        //   to: `/enquiry`
        // }, {
        //   text: 'Manage Accounts',
        //   icon: 'mdi-cash',
        //   module: 'account',
        //   childrens: [{
        //     text: 'Books',
        //     icon: 'mdi-account',
        //     to: `/library/books`
        //   }, {
        //     text: 'Issue Books',
        //     icon: 'mdi-server',
        //     to: `/library/issue-book`
        //   }]
        // }, {
          text: 'Users',
          icon: 'mdi-account-group',
          to: `/users_roles`,
          module: 'users'
        }]
      }
      return menusList
    },
    avatarMenuOptions () {
      return [{
        title: 'Account',
        icon: 'mdi-account',
        to: `/account`
      }, {
        title: 'Logout',
        icon: 'mdi-logout',
        atClick: this.logout
      }]
    }
  },
  methods: {
    getModuleFormsList () {
      const query = [{
        $match: {
          isactive: true,
          'configuration.showasmodule': true
        }
      }]
      this.$_execute('post', 'forms/query', query).then(({ data = [] } = {}) => {
        this.formModuleList = data
        const menuItems = this.$_copy(this.menusList)
        this.formModuleList.forEach((formModuleItem) => {
          if (formModuleItem.configuration.showasmodule && !formModuleItem.isinternal) {
            let hasPermission = false
            if (formModuleItem.configuration.permissions && formModuleItem.configuration.permissions.length) {
              let currentUserPermission = formModuleItem.configuration.permissions.find(y => y.role === this.userDetails.role._id)
              if (currentUserPermission && currentUserPermission.cando.includes('read')) hasPermission = true
            } else hasPermission = true
            if (hasPermission) {
              menuItems.push({
                icon: formModuleItem.configuration.moduleicon,
                text: formModuleItem.configuration.modulename,
                to: `/form-datas/${formModuleItem._id}`,
              })
            }
          }
        })
        this.menuItems = [...menuItems]
      })
    },
    logout () {
      this.$store.commit('logout')
      this.$root.$emit('snackbar', { color: 'success', message: 'Logged out Successfully' })
    },
    checkforPermission (module) { // eslint-disable-line
      // if (this.userDetails.isdeveloper) {
      //   return true
      // } else {
      //   let readPermissionModules = []
      //   for (let i in this.userDetails.role.permissions) {
      //     if(this.userDetails.role.permissions[i].read) readPermissionModules.push(i)
      //   }
      //   if (!readPermissionModules.includes(module)) return false
      //   return true
      // }
      return true
    },
    dragStartHandler (ev) {
      const elem = ev.target || ev.path[0]
      if (!elem) return
      let index = +elem.getAttribute('data-menu-index')
      this.onDragItem = { item: this.menuItems[index], index }
    },
    dragHandler (ev) {
      const elem = ev.target || ev.path[0]
      if (!elem || !this.onDragItem) return
      let index = +elem.getAttribute('data-menu-index')
      this.dropOnItem = { item: this.menuItems[index], index }
      if (this.onDragItem.item.icon !== this.menuItems[index].icon) {
        this.menuItems.splice(this.dropOnItem.index, 1, this.onDragItem.item)
        this.menuItems.splice(this.onDragItem.index, 1, this.dropOnItem.item)
        this.onDragItem = this.$_copy(this.dropOnItem)
      }
    },
    dropHandler () {
      this.onDragItem = null
      this.dropOnItem = null
    },
  }
}
</script>

<style>
  .app-navbar .v-list-item {
    min-height: 54px !important;
  }
  .app-navbar .v-list-item__icon {
    margin-right: 17px !important;
  }
  .app-navbar .v-list-item__action {
    margin: 0 9px 0 0 !important;
  }
  /* width */
  .app-navbar .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0px;
  }
  /* Track */
  .app-navbar .v-navigation-drawer__content::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  .app-navbar .v-navigation-drawer__content::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  .app-navbar .v-navigation-drawer__content::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
